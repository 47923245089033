body {
	color: #404040;
	overflow-x: hidden !important;
}

* {
	outline: none !important;
}

/* Typography
-------------------------------------------------- */
// body {
// 	/*font-family: 'HelveticaNeue-Roman', 'Helvetica Neue', Helvetica, Arial, sans-serif;*/
// }

h1 {
	font-family: 'HelveticaNeue-Light', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-size: 30px;
	/*font-weight: 300;*/
}

h2 {
	font-family: 'HelveticaNeue-Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-size: 22px;
	/*font-weight: bold;*/
}

h3 {
	font-family: 'HelveticaNeue-Light', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-size: 20px;
	/*font-weight: 300;*/
}

h4 {
	font-family: 'HelveticaNeue-Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-size: 16px;
	/*font-weight: bold;*/
}

em,
i {
	font-style: italic;
}

b,
strong {
	font-weight: bold;
}

:-moz-placeholder {
	font-family: 'HelveticaNeue-Light', 'Helvetica Neue', Helvetica, Arial, sans-serif;
   	font-style: italic;
}
::-moz-placeholder {
	font-family: 'HelveticaNeue-Light', 'Helvetica Neue', Helvetica, Arial, sans-serif;
   	font-style: italic;
}
:-ms-input-placeholder {
	font-family: 'HelveticaNeue-Light', 'Helvetica Neue', Helvetica, Arial, sans-serif;
   	font-style: italic;
}
::-webkit-input-placeholder {
	font-family: 'HelveticaNeue-Light', 'Helvetica Neue', Helvetica, Arial, sans-serif;
   	font-style: italic;
}

/* Navbar
-------------------------------------------------- */
.toast-top-right{
	top:55px;
}

.navbar {
	margin-bottom: 0;
}

.navbar-inverse {
	background-color: #f03f2c;
	border-color: #f03f2c;
	border-radius: 0;
}

.navbar-inverse .navbar-brand,
.navbar-inverse .navbar-nav > li > a {
	color: #fff;
}

.navbar-inverse .navbar-nav > .open > a,
.navbar-inverse .navbar-nav > .open > a:focus,
.navbar-inverse .navbar-nav > .open > a:hover {
	background-color: rgba(0, 0, 0, 0.2);
}

.navbar-inverse .navbar-toggle {
    border-color: #fff;
}

.navbar-inverse .navbar-toggle:hover {
    background-color: transparent;
}

.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-form {
	border-color: #fff;
}

/* Hyperlink
-------------------------------------------------- */
a {
	color: #365ebf;
	text-decoration: none;
}
a:hover,
a:active,
a:active:hover,
a:focus {
	color: #365ebf;
	outline: 0;
}
a:active,
a:active:hover,
a:focus {
	color: #2546ac;
}

/* Buttons
-------------------------------------------------- */
.btn {
	border-radius: 2px;
}

.btn {
	padding-left: 20px;
	padding-right: 20px;
}

.btn-primary,
.btn-secondary,
.btn-default {
	font-family: 'HelveticaNeue-Light', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.btn-primary {
	background: #365EBF;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #3E6CC9), color-stop(100%, #2E50B6));
    background: -webkit-linear-gradient(top, #3E6CC9 0%, #2E50B6 100%);
    background: -ms-linear-gradient(top, #3E6CC9 0%, #2E50B6 100%);
    background: linear-gradient(to bottom, #3E6CC9 0%, #2E50B6 100%);
	border-color: #3e6cc9;
	color: #fff;
	font-weight: 300;
}

.btn-primary:active,
.btn-primary:active:focus,
.btn-primary:focus {
	background: #365EBF;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #3E6CC9), color-stop(100%, #2E50B6));
    background: -webkit-linear-gradient(top, #3E6CC9 0%, #2E50B6 100%);
    background: -ms-linear-gradient(top, #3E6CC9 0%, #2E50B6 100%);
    background: linear-gradient(to bottom, #3E6CC9 0%, #2E50B6 100%);
	border-color: #365ebf;
	box-shadow: 0 0 0 2px rgba(54, 94, 191, 0.25);
	color: #fff;
}

.btn-primary:hover,
.btn-primary:active:hover {
	background: #2546ac;
	border-color: #2546ac;
    box-shadow: none;
	color: #fff;
}

.btn-primary-ghost {
    background: #fff;
	border-color: #365EBF;
	color: #365EBF;
}

.btn-primary-ghost:hover {
    background: #365EBF;
	border-color: #365ebf;
	color: #fff;
}

.btn-default {
	background: #F2F2F2;
	background: -ms-linear-gradient(top, #FFFFFF 0%, #F2F2F2 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FFFFFF), color-stop(100%, #F2F2F2));
	background: -webkit-linear-gradient(top, #FFFFFF 0%, #F2F2F2 100%);
	background: linear-gradient(to bottom, #FFFFFF 0%, #F2F2F2 100%);
	border-color: #f03f2c;
	border: 1px solid #DCDCDC;
	box-shadow: initial;
	color: #365ebf;
	font-weight: 300;
	min-width: initial;
}

.btn-default:active,
.btn-default:active:focus,
.btn-default:focus {
	background: #F2F2F2;
	background: -ms-linear-gradient(top, #FFFFFF 0%, #F2F2F2 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FFFFFF), color-stop(100%, #F2F2F2));
	background: -webkit-linear-gradient(top, #FFFFFF 0%, #F2F2F2 100%);
	background: linear-gradient(to bottom, #FFFFFF 0%, #F2F2F2 100%);
	border-color: #365ebf;
	box-shadow: 0 0 0 2px rgba(54, 94, 191, 0.25);
	color: #365ebf;
}

.btn-default:hover,
.btn-default:active:hover {
	background: #fff;
	border: 1px solid #dcdcdc;
    box-shadow: none;
    color: #365ebf;
}

.btn-link {
	color: #365ebf;
}
.btn-link:hover,
.btn-link:active,
.btn-link:active:hover,
.btn-link:active:focus,
.btn-link:focus {
	color: #365ebf;
	text-decoration: none;
}

.btn-link:active,
.btn-link:active:hover,
.btn-link:active:focus,
.btn-link:focus {
	color: #2546ac;
}

.btn-secondary {
	background: #367da3;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #367da3), color-stop(100%, #265d82));
    background: -webkit-linear-gradient(top, #367da3 0%, #265d82 100%);
    background: -ms-linear-gradient(top, #367da3 0%, #265d82 100%);
    background: linear-gradient(to bottom, #367da3 0%, #265d82 100%);
	border-color: #367da3;
	color: #fff;
	font-weight: 300;
}

.btn-secondary:active,
.btn-secondary:active:focus,
.btn-secondary:focus {
	background: #367da3;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #367da3), color-stop(100%, #265d82));
    background: -webkit-linear-gradient(top, #367da3 0%, #265d82 100%);
    background: -ms-linear-gradient(top, #367da3 0%, #265d82 100%);
    background: linear-gradient(to bottom, #367da3 0%, #265d82 100%);
    border-color: #367da3;
	box-shadow: 0 0 0 2px rgba(54, 94, 191, 0.25);
	color: #fff;
}

.btn-secondary:hover,
.btn-secondary:active:hover {
	background: #00496f;
	border-color: #00496f;
    box-shadow: none;
	color: #fff;
}

.btn-group .btn-link {
	padding: 0;
}

.btn-group .btn-link:not(.dropdown-toggle):hover {
	text-decoration: underline;
}

.btn-group > .btn-link + .dropdown-toggle,
.open.btn-group > .dropdown-toggle.btn-link:focus {
	background-color: transparent;
	border: none;
	padding: 0;
}

.btn-group > .btn-link.dropdown-toggle {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
	height: 18px;
	margin: 1px 0 0 6px;
	position: relative;
	width: 18px;
}

.btn-group > .btn-link.dropdown-toggle:after {
	border-left: 1px solid #C7C7C7;
	content: '';
	height: 14px;
	left: -2px;
	position: absolute;
	top: 1px;
	width: 1px;
}

.btn-group > .btn-link.dropdown-toggle:hover,
.btn-group > .btn-link.dropdown-toggle:focus,
.open.btn-group > .btn-link.dropdown-toggle {
    background: #365EBF;
}

.btn-group > .btn-link.dropdown-toggle:hover::after,
.btn-group > .btn-link.dropdown-toggle:focus::after,
.open.btn-group > .btn-link.dropdown-toggle::after {
    border: none;
}

.btn-group > .btn-link .caret {
	border-bottom: 0;
	/*border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 5px solid #404040;*/
	border-top: 6px solid #404040;
	margin: -3px 0 0 0;
}

.btn-group > .btn-link:hover .caret,
.btn-group > .btn-link:focus .caret,
.open.btn-group > .btn-link .caret {
    border-top-color: #fff;
}

/*.open > .dropdown-menu {
    left: auto;
    margin-top: 0;
    right: 0;
}*/

.btn-group > .dropdown-menu {
    border: 1px solid #c7c7c7;
    border-radius: 2px;
    -webkit-box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1);
	display: block;
	opacity: 0;
    padding: 3px 0;
	-webkit-transition: opacity 0.2s ease;
	-moz-transition: opacity 0.2s ease;
	-ms-transition: opacity 0.2s ease;
	-o-transition: opacity 0.2s ease;
	transition: opacity 0.2s ease;
	z-index: -1;
}

.open.btn-group > .dropdown-menu {
    opacity: 1;
    z-index: 10;
}

.btn-group > .dropdown-menu > li > a {
	line-height: 34px;
	margin: 0;
	padding: 0 15px;
}

.open.btn-group > .dropdown-menu > li > a:hover {
	background-color: #365EBF;
	color: #fff;
}

/* Forms
-------------------------------------------------- */
.form-control {
	border-radius: 2px;
}

.form-control {
	border: 1px solid #c8c8c8;
	transition: none;
}

.form-control:hover {
	box-shadow: 0 0 0 2px #e2e2e2;
}

.form-control:focus {
	border: 1px solid #365ebf;
	box-shadow: 0 0 0 2px rgba(54, 94, 191, 0.25);
}

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
}

.checkbox {
    display: block;
    margin: 4px 0 0;
    min-height: 14px;
    position: relative;
}

.checkbox input {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px !important;
    overflow: hidden;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
}

.checkbox label::before {
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid #c7c7c7;
    content: "";
    display: inline-block;
    height: 18px;
    left: 0;
    margin-right: 10px;
    position: absolute;
    top: 1px;
    width: 18px;
}

.checkbox.checked label::before {
    color: #2546ac;
    content: '\f01a';
    font-family: "harmonyicons";
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    white-space: nowrap;
}

/* Pagination
-------------------------------------------------- */
.pagination {
	margin: 0;
}

.pagination > li {
    display: inline;
}

.pagination > li > a {
	color: #365ebf;
}

.pagination > li > a,
.pagination > li > span {
	border: none;
	padding: 0 10px;
}

.pagination > li > a,
.pagination > li > a:hover,
.pagination > li > a:active {
	background-color: transparent;
}

.pagination > li > a:hover {
	color: #365ebf;
	text-decoration: underline;
}

.pagination > .active > a:hover,
.pagination > .disabled > a:hover {
	text-decoration: none;
}

.pagination > li:not(.active):not(.disabled) > a:active,
.pagination > li:not(.active):not(.disabled) > a:active:hover,
.pagination > li:not(.active):not(.disabled) > a:active:focus,
.pagination > li:not(.active):not(.disabled) > a:focus {
	color: #2546ac;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
	background-color: transparent;
	color: inherit;
}

.pagination > li .harmonyicon {
	font-size: 22px;
	margin-top: -2px;
}

/*.pagination > li > a, .pagination > li > span {
	margin-right: 5px;
}
.pagination > li:first-child > a,
.pagination > li:last-child > a {
	border-radius: 0;
}
.pagination > li:last-child > a {
	margin-right: 0;
}
.pagination > li > a,
.pagination > li > a:hover,
.pagination > li > a:active {
	color: #ccc;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
	background-color: transparent;
	border-color: #f03f2c;
	color: #f03f2c;
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
	vertical-align: middle;
}

.alert {
	border-radius: 0px;
	margin-bottom: 15px;
}

button.confirm {
	background-color: #f03f2c !important;
    border-color: #f03f2c !important;
    font-size: 1em;
    text-transform: uppercase;
    border-radius: 0;
}

.sweet-alert h2 {
	font-size: 28px !important;
	font-weight: 400 !important;
	margin: 15px 0 !important;
}
.text-primary {
	color: #f03f2c;
}*/

/* Sticky footer
-------------------------------------------------- */
html {
	position: relative;
	min-height: 100%;
}

body {
	/* Margin bottom by footer height */
	margin-bottom: 80px;
}

.footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	/* Set the fixed height of the footer here */
	height: 60px;
	border-top: 1px solid #ccc;
	text-align: center;
}

.footer p {
	padding-top: 18px;
}

/* Misc.
-------------------------------------------------- */
hr {
	margin: 15px 0;
}

.w100 {
	width: 100px;
}

/* Table */

.tr-checkbox:hover {
	cursor: pointer;
	background-color: #f4f4f4;
}

.btn-tr-quote:hover {
	cursor: pointer;
	background-color: #f1f1f1;
}

/* Tables
-------------------------------------------------- */
.table {
	border-top: 0;
	margin-bottom: 10px;
}

.table > thead > tr > td,
.table > thead > tr > th {
	background-color: #e3eaf3;
    border-bottom-color: #ced9e4;
    border-right: 1px solid #c0d0e4;
    color: #516d88;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
}

.table > thead > tr > td,
.table > thead > tr > th {
	height: 32px;
}

.table > thead > tr > td,
.table > thead > tr > th,
.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th {
	vertical-align: middle;
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th {
    border: 1px solid #dcdcdc;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
	.table > tbody > tr > td,
	.table > tbody > tr > th {
	    background: transparent url("/images/dotted.png") no-repeat left -1px;
	    border-left: none;
	    border-right: none;
		height: 43px;
	}

	.table > tbody > tr > td:first-child,
	.table > tbody > tr > th:first-child {
		background-image: none;
	}
}

@-moz-document url-prefix() {
	.table > tbody > tr > td,
	.table > tbody > tr > th {
	    background: transparent url("/images/dotted.png") no-repeat right -1px;
	    border-left: none;
	    border-right: none;
		height: 43px;
	}

	.table > tbody > tr > td:last-child,
	.table > tbody > tr > th:last-child {
		background-image: none;
	}
}

.table > thead > tr > td:first-of-type,
.table > thead > tr > th:first-of-type,
.table > tbody > tr > td:first-of-type,
.table > tbody > tr > th:first-of-type,
.table > tfoot > tr > td:first-of-type,
.table > tfoot > tr > th:first-of-type {
	border-left: 0;
}

.table > thead > tr > td:last-of-type,
.table > thead > tr > th:last-of-type,
.table > tbody > tr > td:last-of-type,
.table > tbody > tr > th:last-of-type,
.table > tfoot > tr > td:last-of-type,
.table > tfoot > tr > th:last-of-type {
	border-right: 0;
}

.table > tbody > tr:last-child > td,
.table > tbody > tr:last-child > th,
.table > tfoot > tr:last-child > td,
.table > tfoot > tr:last-child > th {
	border-bottom-width: 2px;
}

.table-hover tr:hover td {
	background-color: #f3f8fe;
}

.table-hover tr td:not(.table-actions) a,
.table-hover tr td:not(.table-actions) a:visited {
	color: #404040;
}

.table-hover tr:hover td:not(.table-actions) a,
.table-hover tr:hover td:not(.table-actions) a:visited {
	color: #365ebf;
}

.table .checkbox {
	margin: 0;
	line-height: 0;
}

/* Tooltips
-------------------------------------------------- */
.tooltip.bottom {
	/*margin-left: 140px;*/
    padding: 10px 0;
}
.tooltip.bottom .tooltip-arrow {
	border-bottom-color: #131f2b;
	border-right-color: transparent;
	border-width: 0 15px 15px 0;
	/*left: 10px !important;*/
	margin-left: 0;
	top: 0;
}
.tooltip-inner {
	background-color: #131f2b;
	border-radius: 2px;
	display: inline-block;
	font-weight: 300;
	max-width: 300px;
	padding: 10px;
	text-align: left;
}
.tooltip-container {
	background-color: transparent;
	/*width: 300px;*/
}

/* Tabs
-------------------------------------------------- */
.nav.nav-tabs {
	border-bottom: 1px solid #ccc;
	box-shadow: 0 4px 0 #f1f1f1;
}
.nav > li > a:hover,
.nav > li > a:focus {
  	background-color: transparent;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
	color: #404040;
	background-color: transparent;
	border-bottom: none;
	border-left: none;
	border-right: 1px dotted #999;
	border-top: none;
}
.nav-tabs > li > a,
.nav-tabs > li > a:hover,
.nav-tabs > .active > a,
.nav-tabs > .active > a:hover,
.nav-tabs > .active > a:focus,
.nav-tabs.nav-justified > li > a,
.nav-tabs.nav-justified > li > a:hover,
.nav-tabs.nav-justified > .active > a,
.nav-tabs.nav-justified > .active > a:hover,
.nav-tabs.nav-justified > .active > a:focus {
	border: none;
	border-radius: 0;
	border-right: 1px dotted #999;
	margin-bottom: 10px;
	margin-right: 0;
	padding-bottom: 3px;
	padding-top: 3px;
}
.nav-tabs > li:last-child > a,
.nav-tabs > li:last-child > a:hover,
.nav-tabs > .active:last-child > a,
.nav-tabs > .active:last-child > a:hover,
.nav-tabs > .active:last-child > a:focus,
.nav-tabs.nav-justified > li:last-child > a,
.nav-tabs.nav-justified > li:last-child > a:hover,
.nav-tabs.nav-justified > .active:last-child > a,
.nav-tabs.nav-justified > .active:last-child > a:hover,
.nav-tabs.nav-justified > .active:last-child > a:focus {
	border-right: 0;
}
.nav-tabs > li > a,
.nav-tabs > .active > a,
.nav-tabs.nav-justified > li > a,
.nav-tabs.nav-justified > .active > a {
	color: #404040;
}
.nav-tabs > .active {
	border-bottom: 4px solid #365ebf;
}
.nav-tabs > .active > a,
.nav-tabs.nav-justified > .active > a {
	font-weight: bold;
}

.tab-content {
	margin-bottom: 20px;
	margin-top: 20px;
}

/* Alerts
-------------------------------------------------- */
#show-message,
#show-modal-message  {
	display: none;
    width: 100%;
}

#show-message .alert,
#show-modal-message .alert  {
	color: #404040;
}

#show-message .alert,
#show-modal-message .alert  {
	font-size: 12px;
}

#show-message .alert-warning,
#show-modal-message .alert-warning  {
	background-color: transparent;
	border-color: #f2b835;
	border-radius: 0;
}

#show-message .alert-warning .harmonyicon,
#show-modal-message .alert-warning .harmonyicon  {
	color: #f2b835;
	font-size: 27px;
	font-weight: normal;
	margin-right: 8px;
}

#show-message .alert-warning .message-title,
#show-modal-message .alert-warning .message-title  {
	font-size: 14px;
	font-weight: bold;
}

/* Lists
-------------------------------------------------- */
.dl-horizontal dt {
	color: #486c8f;
	font-weight: normal;
	text-transform: uppercase;
	text-align: left;
}

.dl-horizontal.col-sm dt {
	width: 100px;
}

.dl-horizontal.col-sm dd {
	margin-left: 100px;
}

/* List groups
-------------------------------------------------- */
.list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.list-group-item:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.list-group-item {
	border: none;
	border-bottom: 1px solid #dcdcdc;
	margin-bottom: 0;
}

.list-group-item:last-of-type {
	border-bottom: 0;
}

.list-group.collapsed > .list-group-item:last-of-type {
	border-bottom: 1px solid #dcdcdc;
}

.list-group.collapsed > .list-group-item:last-child {
	border-bottom: 0;
}

.list-group-item:hover {
	background-color: #f3f8fe;
}

.list-group > .list-group-item > .list-group {
	background-color: #fff;
	margin-bottom: 0;
}

.list-group > .list-group-item > .list-group .list-group-item {
	border: none;
}

/* Helper classes
-------------------------------------------------- */
.text-muted {
	color: #b8b8b8;
}

.text-primary {
	color: #0098cd;
}

.text-success {
	color: #56b349;
}

.text-info {
	color: #486c8f;
}

.text-warning {
	color: #f2b835;
}

.text-danger {
	color: #d93b42;
}

/* Payments
-------------------------------------------------- */
#total-payment {
    text-align: right;
}
.radio-hidden {
    display: none;
    float:left;
}
.active-method {
    background: inherit;
    color: inherit;
}
.cash-btn {
    border-radius: 2px 0 0 2px;
}
.installment-btn,
.installment-btn:hover,
.installment-btn:active {
    border-radius: 0;
    border-left: none;
    border-right: none;
}
.credit-btn {
    border-radius: 0 2px 2px 0;
}
.installment-hide {
    display: none;
}
.disabled {
    pointer-events: none;
}
.payments-content-header {
    max-height: unset !important;
}
.search-quote-payments {
    margin-top: 30px;
}
.quote-not-found h2 {
    display: none;
}
.radio-hidden {
    display: none;
}
.selected-payment {
    background-color: #f3f8fe;
}
